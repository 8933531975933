<template>
  <div class="main_style">
    <NavBarHome v-if="navBarStyle === '1'" textColor="#000000" :iconUrl="require('@/assets/img/home/logo-2.png')"/>
    <NavBar v-else />
    <div class="header">
      <div class="header-text">
        <p class="header-title">众享区块链供应链金融平台</p>
        <p class="header-desc">
          众享区块链供应链金融平台针对供应链上小微企业融资难困境，依托区块链上核心企业的信任传递，围绕核心企业及上下游多级供应链企业，借助保险、信托、仓储、物流等服务商共同打造供应链金融产业生态闭环。
        </p>
      </div>
    </div>
    <!-- 产品介绍 -->
    <div class="intro">
      <div class="commom-title">产品介绍</div>
      <div class="intro-content">
        <p>
          众享区块链供应链金融平台针对供应链上小微企业融资难困境，依托区块链上核心企业的信任传递，围绕核心企业及上下游多级供应链企业，借助保险、信托、仓储、物流等服务商共同打造供应链金融产业生态闭环，实现信息流、商流、资金流、物流四流合一，促进多方企业互利共生，良性发展。
        </p>
      </div>
    </div>
    <!-- 核心功能 -->
    <div class="function">
      <div class="commom-title">核心功能</div>
      <div class="content">
        <el-row :gutter="24" class="grid">
          <el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img src="@/assets/img/product/Finance/function-1.png" />
              </div>
              <div class="title">应收企业</div>
              <div class="text">
                可在平台上进行融资，优化现金流，提升资金周转效率
              </div>
            </div></el-col
          >
          <el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img src="@/assets/img/product/Finance/function-2.png" />
              </div>
              <div class="title">应付企业</div>
              <div class="text">
                可在平台上向应收企业进行提前支付撮合赚取收益，使得资金的合理有效运用
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content">
              <div class="img">
                <img src="@/assets/img/product/Finance/function-3.png" />
              </div>
              <div class="title">金融机构</div>
              <div class="text">
                可在信息透明、风险可控的条件下为企业融资并赚取融资利息
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content">
              <div class="img">
                <img src="@/assets/img/product/Finance/function-4.png" />
              </div>
              <div class="title">核心企业</div>
              <div class="text">
                众享供应链金融方案解决了上下游供应商、经销商的融资问题，优化供应链整体效应，增强了企业核心竞争力
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 产品架构 -->
    <div class="framework">
      <div class="commom-title">产品架构</div>
      <div class="framework-content">
        <img src="https://image.peerfintech.cn/peerfintech_website_v2/product/Finance/framework.png" />
      </div>
    </div>
    <!-- 产品优势 -->
    <div class="advantage">
      <div class="commom-title">产品优势</div>
      <div class="advantage-content">
        <el-row :gutter="24">
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img src="@/assets/img/product/Finance/advantage-1.png" />
              </div>
              <div class="title">信息透明可追溯</div>
              <div class="text">
                基于区块链技术，将各项数据记录在联盟链上，可对信息流、商流、资金流、物流的变动进行追踪，提高参与方之间的信息透明度。
              </div>
            </div></el-col
          >
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img src="@/assets/img/product/Finance/advantage-2.png" />
              </div>
              <div class="title">债权拆分促流转</div>
              <div class="text">
                通过智能合约约定规则，使不同层级的供应商之间的债权可以拆分和流转，提高了资金周转率。
              </div>
            </div></el-col
          >
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img src="@/assets/img/product/Finance/advantage-3.png" />
              </div>
              <div class="title">数据真实增信用</div>
              <div class="text">
                上链数据无法篡改，确保供应链各方上链数据的真实性，增进中小企业的信用。
              </div>
            </div></el-col
          >
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img src="@/assets/img/product/Finance/advantage-4.png" />
              </div>
              <div class="title">降低风险促融资</div>
              <div class="text">
                转变传统供应链金融授信模式，区块链技术确保贸易背景真实，将授信来源从个体信用转变为产业链信用，为供应链中的中小企业增信，帮助金融机构把控风险，优化和提高核心企业的供应链质量。
              </div>
            </div></el-col
          >
        </el-row>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar";
import NavBarHome from "@/components/NavBarHome";
import Footer from "@/components/Footer";
import {scrollMixin} from '@/mixins/scroll.js'
export default {
  mixins: [scrollMixin],
  components: {
    NavBar,
    NavBarHome,
    Footer,
  },
  mounted() {
    // 页面回退顶部
    window.scrollTo(0, 0);
  },
};
</script>
<style lang="scss" scoped>
.main_style {
  background-color: #ffffff;
  .content-width {
    width: 1200px;
    margin: 0px auto;
  }
  .commom-title {
    padding: 70px 0px 50px;
    font-size: 32px;
    font-family: "PingFangSC-Medium";
    color: rgba(0, 0, 0, 0.85);
    line-height: 45px;
    text-align: center;
  }
  .header {
    width: 100%;
    height: 540px;
    background: url("https://image.peerfintech.cn/peerfintech_website_v2/product/Finance/bg.png") center/cover
      no-repeat;
    .header-text {
      @extend .content-width;
      .header-title {
        font-size: 36px;
        font-family: "PingFangSC-Medium";
        color: #000000;
        line-height: 50px;
        padding-top: 160px;
      }
      .header-desc {
        width: 510px;
        color: rgba(0,0,0,0.75);
        font-size: 20px;
        line-height: 33px;
        margin-top: 36px;
      }
    }
  }
  .intro {
    padding-bottom: 70px;
    .intro-content {
      @extend .content-width;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.65);
      line-height: 25px;
    }
  }
  .function {
    background: #f4f7fc;
    .content {
      @extend .content-width;
      padding-bottom: 20px;
      .line-title {
        display: flex;
        width: 100%;
        align-items: center;
        margin-bottom: 24px;
        div:nth-child(2) {
          padding: 0px 77px;
          font-size: 26px;
          color: #0075ff;
          line-height: 37px;
        }
        div:nth-child(1),
        div:nth-child(3) {
          flex: 1;
          height: 1px;
          background: #0075ff;
        }
      }
      .grid {
        padding-bottom: 26px;
        .grid-content {
          height: 352px;
          margin-bottom: 24px;
          background: #ffffff;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 42px 24px 0px;
          .img {
            width: 124px;
            height: 124px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .title {
            padding: 24px 0px;
            font-size: 22px;
            font-family: "PingFangSC-Medium";
            color: rgba(0, 0, 0, 0.85);
            line-height: 30px;
          }
          .text {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.45);
            line-height: 22px;
            text-align: center;
          }
        }
      }
    }
  }
  .framework {
    padding-bottom: 70px;
    .framework-content {
      width: 31.1%;
      min-width: 598px;
      margin: 0px auto;
      img {
        width: 100%;
      }
    }
  }
  .advantage {
    background: #f4f7fc;
    .advantage-content {
      @extend .content-width;
      padding-bottom: 70px;
      .el-row {
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .grid-content {
        height: 370px;
        background: #ffffff;
        box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.1);
        padding: 40px 48px 0px 40px;
        .img {
          width: 124px;
          height: 124px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .title {
          padding: 24px 0px;
          font-size: 22px;
          font-family: "PingFangSC-Medium";
          color: rgba(0, 0, 0, 0.85);
          line-height: 30px;
        }
        .text {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.45);
          line-height: 22px;
        }
      }
    }
  }
  .project {
    .project-content {
      @extend .content-width;
      padding-bottom: 70px;
      .grid-content {
        height: 194px;
        background: #f4f7fc;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px 48px 0px;
        .img {
          width: 66px;
          height: 66px;
          margin-bottom: 20px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .title {
          font-size: 22px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 30px;
          text-align: center;
        }
      }
      .el-row {
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
